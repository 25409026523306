export const framed = true
export const partner = 'ftn'
export const overridePerms = true
export const authed = false
export const customPresets = true

// CSS Overrides
export const overridePrimary = '#1b9bdc'
export const overrideBackground = '#1D1F23'
export const overrideBackgroundSecondary = 'linear-gradient(0deg,#24272b,#3d4148 120%)'
export const overrideColor = '#e0e0e3'
export const overrideSecondary = '#999'
export const overrideCheckboxColor = '#818181'
export const overrideFont = 'Red Hat Display'

export const allowCheatsheets = true
